"use strict";
/**
 * @def AbstractTree
 * @isGroup true
 * 
 * @CSSify styleName : AbstractTreeHost/true
 * @CSSify styleName : AbstractTreeHeader/true
 */


var TypeManager = require('src/core/TypeManager');



var abstractTreeDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"flex": "1 1 0",
		"alignItems": "flex-start",
		"boxSizing": "border-box"
	},
	{
		"selector": "tree-branch:nth-child(3)",
		"marginLeft": "0"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'AbstractTreeHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'CompoundComponent',
			nodeName : 'folded-tree',
			props : [
				{selected : undefined},
				{expanded : true}
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}),
		members : [
			TypeManager.createComponentDef({
				type : 'VaritextButtonWithPicto',
				nodeName : 'header',
				// this is a big hack of shit (should be an attribute, but not... should be a "DOM" attribute... -> setAttribute(). TODO: fix after re-implementation of _arias&glyphs)
				states : [
					{role : "heading"},
					{expanded : undefined} 
				],
				props : [
					{headerTitle : undefined}
				],
				reactOnSelf : [
					{
						from : 'headerTitle',
						to : 'content'
					}
				]/**@CSSify Style componentStyle : AbstractTreeHeader */
			})
		]
	}, null, 'rootOnly');
	
	return moduleDef;
}

abstractTreeDef.__factory_name = 'abstractTreeDef';
module.exports = abstractTreeDef;
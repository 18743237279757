/**
 * @def TabPanel
 * @isGroup true
 * 
 * @CSSify styleName : TabPanelHost
 * @CSSify styleName : TabPanelSlotTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
const {TemplateFactory, CreateStyle} = require('formantCore');

const tabPanelDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var templateStyles = [

	{
		"selector": ":host tab-header",
		"boxSizing": "border-box",
		"backgroundColor": "#282828",
		"minWidth": "140px",
		"width": "fit-content",
		"height": "44px",
		"border": "1px solid #383838",
		"borderRadius": "7px 0px 0px 7px",
		"borderWidth": "1px 1px 0px 1px",
		"padding": "7px",
		"textAlign": "center",
		"cursor": "pointer"
	},
	{
		"selector": ":host",
		"backgroundColor": "#282828"
	},
	{
		"selector": ":host tab-header[highlighted]",
		"backgroundColor": "#555"
	}

	];
	var templateStylesUseCache = {
		use : undefined,
		nameInCache : 'TabPanelSlotTemplateStyles'
	}

	var hostStyles = [

	{
		"selector": ":host pseudo-slot",
		"display": "block",
		"boxSizing": "border-box",
		"minHeight": "44px"
	},
	{
		"selector": ":host  pseudo-slot:nth-child(2)",
		"display": "flex"
	},
	{
		"selector": ":host  pseudo-slot:nth-child(3)",
		"border": "1px inset #444",
		"borderRadius": "7px"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'TabPanelHostStyles'
	}
	
	const slotDef = TemplateFactory.createDef({
		host : TemplateFactory.createDef({
			type : 'ComponentWithView',
			nodeName : 'pseudo-slot',
			states : [
				{'slot-id' : undefined},
				{'is-embedded' : undefined},
				{'position' : undefined}
				
			],
			subscribeOnChild : [
				{
					on : 'update',
					subscribe : function(e) {
						if (e.bubble)
							this.trigger('update', e.data, true);
					}
				}
			],
				sWrapper : CreateStyle(
						templateStylesUseCache.use ? templateStylesUseCache.nameInCache : null,
						templateStyles
					)
		})
	});
	
	const moduleDef = TemplateFactory.createDef({
		host : TemplateFactory.createDef({
			nodeName : 'smart-tabs',
			isCompound : true,
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}),
		lists : [
			TemplateFactory.createDef({
				type : 'ComponentList',
				template : slotDef
			})
		]
	});
	
	return moduleDef;
}

module.exports = tabPanelDef;
"use strict";
/**
 * @def textInput
 * 
 * @CSSify styleName : CheckboxInputHost
 * 
 */

const {TypeManager, CreateStyle} = require('formantCore');

var textInputDef = function(uniqueID, options) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "flex"
	},
	{
		"selector": "label",
		"display": "inline-block",
		"width": "332px"
	},
	{
		"selector": "input",
		"color": "#EAEAEA",
		"background": "#1a2327",
		"alignSelf": "flex-end",
		"border": "1px solid #444"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'CheckboxInputHostStyles'
	}
	
	// DUMMY Def: only used to assign a sWrapper anda sOverride prop to the "real" def in the component
	var textInputDef = TypeManager.createComponentDef({
		UID : 'dummy',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	}); 
	return textInputDef;
}

module.exports = textInputDef;
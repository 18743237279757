"use strict";
/**
 * @def textInput
 * 
 * @CSSify styleName : TextInputHost
 * 
 */

const {TemplateFactory, CreateStyle} = require('formantCore');

var textInputDef = function(uniqueID, options) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "block"
	},
	{
		"selector": "label",
		"display": "inline-block",
		"width": "157px"
	},
	{
		"selector": "input",
		"color": "#EAEAEA",
		"background": "#1a2327",
		"height": "25px",
		"width": "345px",
		"border": "1px solid #444"
	},
	{
		"selector": ":host textarea",
		"resize": "none",
		"backgroundColor": "#283235",
		"color": "#C7C7C7"
	},
	{
		"selector": "tooltip"
	},
	{
		"selector": ":host(:not([valid])) input, :host(:not([valid])) textarea",
		"outline": "2px ridge Orange"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'TextInputHostStyles'
	}
	
	// DUMMY Def: only used to assign a sWrapper anda sOverride prop to the "real" def in the component
	var textInputDef = TemplateFactory.createHostDef({
		UID : 'dummy',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	}); 
	return textInputDef;
}

module.exports = textInputDef;
"use strict";
/**
 * @def SubmitButton
 * 
 * @CSSify styleName : SubmitButtonHost
 * @CSSify styleName : SubmitButtonTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */

const {TypeManager, CreateStyle} = require('formantCore');

var submitButtonDef = function(uniqueID, options) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
	
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"background": "#0c849e",
		"color": "#EAEAEA",
		"font": "inherit",
		"padding": "12px 21px",
		"boxSizing": "border-box",
		"maxWidth": "300px",
		"minWidth": "122px",
		"marginBottom": "5px",
		"borderColor": "#383838 #303030 #303030 #383838",
		"cursor": "pointer",
		"textAlign": "center"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'SubmitButtonHostStyles'
	}
	
	return TypeManager.createComponentDef({
		nodeName : 'dummy',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	}); 
}

submitButtonDef.__factory_name = 'submitButtonDef';
module.exports = submitButtonDef;
"use strict";
/**
 * @def extensibleTableDef
 * @isGroup true
 * 
 * @CSSify styleName : ExtensibleTableHost
 * @CSSify styleName : ExtensibleTablePseudoSlot
 */


//var TypeManager = require('src/core/TypeManager');
const {TemplateFactory, CreateStyle} = require('formantCore');

var extensibleTableDef = function(uniqueID, options, model) {
		
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"background": "#012B39",
		"borderRadius": "0.25em",
		"borderCollapse": "collapse",
		"margin": "1em"
	},
	{
		"selector": "th",
		"borderBottom": "1px solid #364043",
		"color": "#E2B842",
		"fontSize": "0.85em",
		"fontWeight": "600",
		"padding": "0.5em 1em",
		"textAlign": "left",
		"cursor": "pointer"
	},
	{
		"selector": "td",
		"color": "#fff",
		"fontWeight": "400",
		"padding": "0.65em 1em"
	},
	{
		"selector": ".disabled td",
		"color": "#4F5F64"
	},
	{
		"selector": "tbody tr",
		"transition": "background 0.25s ease"
	},
	{
		"selector": "tbody tr:hover",
		"background": "#014055"
	},
	{
		"selector": "tbody tr[selected]",
		"backgroundColor": "#115065"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'ExtensibleTableHostStyles'
	}
	
	
	const slotDef = TemplateFactory.createHostDef({
//		host : TemplateFactory.createDef({
			type : 'ComponentWithView',
			nodeName : 'tbody',
			states : [
				{'slot-id' : undefined},
				{'is-embedded' : undefined},
				{'position' : undefined}
				
			],
			subscribeOnChild : [
				{
					on : 'update',
					subscribe : function(e) {
						if (e.bubble)
							this.trigger('update', e.data, true);
					}
				}
			]/**@CSSify Style componentStyle : ExtensibleTablePseudoSlot */
//		}, null, 'hostOnly')
	});
	
	const moduleDef = TemplateFactory.createDef({
		host : TemplateFactory.createHostDef({
//			type : 'ComposedCompnent', 				// this is implicit, as we call the CompoundComponent ctor in the TabPanel ctor
			nodeName : 'extensible-table',
			props : [
				{updateChannel : undefined}
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}),
		lists : [
			TemplateFactory.createDef({
				type : 'ComponentList',
				template : slotDef
			})
		]
	});
	
	return moduleDef;
}

module.exports = extensibleTableDef;
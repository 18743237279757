"use strict";
/**
 * @def formComponent
 * @CSSify styleName : FormComponentHost
 * @CSSifyTheme themeName : basic-light
 */

const {TemplateFactory, CreateStyle} = require('formantCore');

var formComponentDef = function(uniqueID, options) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"flexDirection": "column",
		"boxSizing": "border-box",
		"height": "100%",
		"padding": "7px"
	},
	{
		"selector": ":host section:nth-child(3)",
		"display": "flex",
		"flexDirection": "column",
		"alignItems": "end",
		"paddingTop": "5px"
	},
	{
		"selector": ":host section:nth-child(2)",
		"display": "flex",
		"flexDirection": "column",
		"width": "407px",
		"height": "100%"
	},
	{
		"selector": ":host fieldset",
		"width": "375px",
		"border": "1px solid #383838",
		"boxSizing": "border-box"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'FormComponentHostStyles'
	}
	
	return TemplateFactory.createDef({
		host : TemplateFactory.createDef({
			isCompound : true,
			nodeName : 'smart-form',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
			// Reminder
//			states : [
//				{action : ''}
//			]
			
		}),
		subSections : [
			TemplateFactory.createDef({
				host : TemplateFactory.createDef({
					type : 'ComponentWithView',
					nodeName : 'section'
				})
			}),
			TemplateFactory.createDef({
				host : TemplateFactory.createDef({
					type : 'ComponentWithView',
					nodeName : 'section'
				})
			})
		],
		members : [
			// Sample code
//			TemplateFactory.createDef({
//				host : TemplateFactory.createDef({
//					type : 'SubmitButton',
//					nodeName : 'button',
//					section : 1,
//					props : [
//						{text : 'Open File'}
//					]
//				})
//			}),
//			TemplateFactory.createDef({
//				host : TemplateFactory.createDef({
//					type : 'CancelButton',
//					nodeName : 'button',
//					section : 1,
//					props : [
//						{text : 'Cancel'}
//					]
//				})
//			})
		]
	}); 
}

module.exports = formComponentDef;